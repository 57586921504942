(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("coreUtilsLib"), require("platformUtils"), require("prop-types"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "coreUtilsLib", "platformUtils", "prop-types", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["host-platform-api"] = factory(require("lodash"), require("coreUtilsLib"), require("platformUtils"), require("prop-types"), require("mobx"));
	else
		root["host-platform-api"] = factory(root["lodash"], root["coreUtilsLib"], root["platformUtils"], root["prop-types"], root["mobx"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__22__) {
return 