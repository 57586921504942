'use strict'

const _ = require('lodash')
const platformUtils = require('santa-platform-utils')
const coreUtilsLib = require('santa-core-utils')
const widgetModel = require('./widgetModel')

const RemoteModelInterface = platformUtils.RemoteModelInterface
const RemoteGlobalsInterface = platformUtils.RemoteGlobalsInterface
const componentsHooks = platformUtils.componentsHooks

const getPlatformModels = ({runtimeDal, compIds, contextId, getCompReactClass}) => {
    const isUsingRenderer = _.isFunction(runtimeDal.getPlatformModels)
    if (isUsingRenderer) {
        const {components, connections} = runtimeDal.getPlatformModels(contextId)
        return {components, connections}
    }

    return {
        components: compIds.reduce((model, compId) => {
            model[compId] = widgetModel.getCompModel({runtimeDal, compId, contextId, getCompReactClass})
            return model
        }, {}),
        connections: widgetModel.getConnectionsModel({runtimeDal, compIds, contextId})
    }
}

function generateRemoteModelInterface({
    runtimeDal,
    compIds,
    siteStructureData,
    pageData,
    widgetProperties,
    onUpdateCallback,
    contextId,
    getCompReactClass,
    getGhostStructure
}) {
    const remoteModelInterface = new RemoteModelInterface(undefined, onUpdateCallback)
    remoteModelInterface.registerHook(remoteModelInterface.getHooks().data, 'wysiwyg.viewer.components.Repeater', componentsHooks.repeaters.updateRepeaterItems)

    const {components, connections} = getPlatformModels({runtimeDal, compIds, contextId, getCompReactClass})

    _.forEach(_.keys(components), compId => remoteModelInterface.addComponent(compId, components[compId]))

    remoteModelInterface.addSiteStructure(siteStructureData)

    remoteModelInterface.addPageData(pageData)

    remoteModelInterface.addConnections(connections)

    if (getGhostStructure) {
        const {ghostComps, ghostConnections} = widgetModel.getGhostCompAndConnectionsModels({ghostStructure: getGhostStructure(), runtimeDal, compIds, components})

        _.forEach(_.keys(ghostComps), ghostId => remoteModelInterface.addComponent(ghostId, ghostComps[ghostId], true))

        remoteModelInterface.addConnections(ghostConnections, true)
    }

    remoteModelInterface.resetComponentsScope()

    remoteModelInterface.addEventTypes(coreUtilsLib.siteConstants.ACTION_TYPES)

    remoteModelInterface.updateWidgetProperties(widgetProperties)

    return remoteModelInterface
}

function createRemoteModelInterface(model, onUpdateCallback) {
    const remoteModelInterface = new RemoteModelInterface(model, onUpdateCallback)
    remoteModelInterface.registerHook(remoteModelInterface.getHooks().data, 'wysiwyg.viewer.components.Repeater', componentsHooks.repeaters.updateRepeaterItems)
    return remoteModelInterface
}

function generateRemoteGlobalsInterface(navigationData, sessionInfo, siteMemberData, multilingualInfo, appsData, isApplicationStudio, deviceType, loghtboxContext, contextId) {
    const remoteGlobalsInterface = new RemoteGlobalsInterface()

    remoteGlobalsInterface.addNavigation(navigationData)

    remoteGlobalsInterface.addSiteMemberData(siteMemberData)

    remoteGlobalsInterface.addMultilingualInfo(multilingualInfo)

    remoteGlobalsInterface.addAppsData(appsData)

    remoteGlobalsInterface.addSessionInfoProp(sessionInfo)

    remoteGlobalsInterface.addDeviceType(deviceType)

    remoteGlobalsInterface.addLightboxContext(loghtboxContext)

    remoteGlobalsInterface.addContextId(contextId)

    remoteGlobalsInterface.addIsApplicationStudio(isApplicationStudio)

    return remoteGlobalsInterface
}

module.exports = {
    generateRemoteModelInterface,
    createRemoteModelInterface,
    generateRemoteGlobalsInterface
}
