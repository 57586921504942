'use strict'

const _ = require('lodash')
const coreUtilsLib = require('santa-core-utils')

const REPEATER_TYPE = 'wysiwyg.viewer.components.Repeater'

function getInitialState(propsInfo, compType, getCompReactClass) {
    const compClass = getCompReactClass(compType)
    if (compClass && compClass.publicState) {
        return compClass.publicState(null, propsInfo)
    }

    return {}
}

function shouldResolveCompDataFromFull(compId, isDescendantOfRepeater) {
    return !coreUtilsLib.displayedOnlyStructureUtil.isDisplayedComponent(compId) && isDescendantOfRepeater
}

function getAncestorOfRepeaterType(runtimeDal, compId) {
    return runtimeDal.getAncestorOfType(compId, REPEATER_TYPE)
}

function getCompModel({runtimeDal, compId, contextId, getCompReactClass}) {
    const compType = runtimeDal.getCompType(compId)
    const repeaterComp = getAncestorOfRepeaterType(runtimeDal, compId)
    const isDescendantOfRepeater = !!repeaterComp
    const resolveCompDataFromFull = shouldResolveCompDataFromFull(compId, isDescendantOfRepeater)
    const compState = getCompState(runtimeDal, compId, compType, getCompReactClass, resolveCompDataFromFull)
    const compActionsAndBehaviors = runtimeDal.getActionsAndBehaviors(compId)
    const isDisplayedComponent = coreUtilsLib.displayedOnlyStructureUtil.isDisplayedComponent(compId)

    const compModel = {
        parent: runtimeDal.getParentId(compId),
        type: compType,
        state: compState,
        layout: runtimeDal.getCompLayout(compId),
        design: runtimeDal.getCompDesign(compId, resolveCompDataFromFull),
        isDisplayed: runtimeDal.isDisplayed(compId),
        id: runtimeDal.getCompName(compId),
        data: runtimeDal.getCompData(compId, resolveCompDataFromFull),
        style: runtimeDal.getCompStyle(compId),
        fullData: runtimeDal.getCompData(compId, true),
        props: runtimeDal.getCompProps(compId, resolveCompDataFromFull),
        children: runtimeDal.getChildrenIds(compId),
        events: _.map(compActionsAndBehaviors, actionAndBehavior => _.assign({eventType: _.get(actionAndBehavior, 'action.name')}, _.get(actionAndBehavior, 'behavior.params'))),
        displayedRoot: isDisplayedComponent && repeaterComp ? _.get(repeaterComp, 'id') : null,
        displayedOnlyComponents: isDisplayedComponent ? [] : _.map(runtimeDal.getDisplayedOnlyCompsForFullComp(compId, contextId), 'id')
    }

    return compModel
}

function getCompState(runtimeDal, compId, compType, getCompReactClass, resolveCompDataFromFull) {
    const baseModel = {
        data: runtimeDal.getCompData(compId, resolveCompDataFromFull),
        fullData: runtimeDal.getCompData(compId, true),
        props: runtimeDal.getCompProps(compId, resolveCompDataFromFull)
    }

    const compState = runtimeDal.getCompState(compId)
    if (_.isUndefined(compState) || _.isEmpty(compState)) {
        return getInitialState(baseModel, compType, getCompReactClass)
    }
    return compState
}

function getAllCompsConnections(compIds, runtimeDal, contextId) {
    const connections = _.reduce(compIds, (result, compId) => {
        const compConnections = runtimeDal.getCompConnections(compId)
        const compConnectionsWithIds = _.map(compConnections, connection => {
            let newConnection
            if (connection.type === 'WixCodeConnectionItem') {
                newConnection = _.assign({}, connection, {controllerId: contextId, config: null})
            } else {
                newConnection = _.defaults({}, connection, {config: null})
            }
            return {connection: newConnection, compId}
        })
        return result.concat(compConnectionsWithIds)
    }, [])
    return connections
}

function getConnectionsModel({runtimeDal, compIds, contextId}) {
    const connections = getAllCompsConnections(compIds, runtimeDal, contextId)
    return _(connections)
        .groupBy('connection.controllerId')
        .mapValues(connectionsByController => _(connectionsByController)
            .groupBy('connection.role')
            .mapValues(connectionsByRole => _(connectionsByRole)
                .keyBy('compId')
                .mapValues('connection.config')
                .value())
            .value())
        .value()
}

function getConnectionRoles(compIds, runtimeDal) {
    return _(compIds)
        .flatMap(runtimeDal.getCompConnections)
        .filter('controllerId')
        .groupBy('controllerId')
        .mapValues(controllerConnections => new Set(_.map(controllerConnections, 'role')))
        .value()
}

function getDataMap(components) {
    return _(components)
        .map('data')
        .compact()
        .keyBy('id')
        .value()
}

function getGhostCompAndConnectionsModels({ghostStructure = {}, runtimeDal, compIds, components}) {
    const ghostComps = {}
    const ghostConnections = {}

    const connectionRoles = getConnectionRoles(compIds, runtimeDal)
    const dataMap = getDataMap(components)

    _.forEach(connectionRoles, (existingConnections, controllerId) => {
        const {applicationId, settings: {type: widgetId} = {}} = dataMap[controllerId] || {}
        const fallbackStructure = _.get(ghostStructure, [applicationId, widgetId], {})

        _.forEach(fallbackStructure, (comp, compRole) => {
            if (!existingConnections.has(compRole)) {
                const ghostId = comp.id + controllerId
                const ghostComp = _.assign({}, comp, {
                    id: ghostId,
                    parent: _.findKey(fallbackStructure, potentialParent => _.includes(potentialParent.children, compRole)) || null,
                    type: comp.componentType,
                    children: comp.children || [],
                    displayedOnlyComponents: []
                })

                _.set(ghostConnections, [controllerId, compRole], {[ghostId]: null})
                _.set(ghostComps, ghostId, ghostComp)
            }
        })
    })
    return {ghostComps, ghostConnections}
}

module.exports = {
    getCompModel,
    getConnectionsModel,
    getGhostCompAndConnectionsModels
}
